














import { Component, Vue } from 'vue-property-decorator'
import { AppModule } from '@/store/module'

import FooterBlock from '@/components/FooterBlock.vue'
import ContactBlock from '@/components/ContactBlock.vue'

@Component({
  components: {
    FooterBlock,
    ContactBlock,
  },
})
export default class UsePolicy extends Vue {
  get isH5() {
    return AppModule.isH5
  }
}
